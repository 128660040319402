export default {
  adMediaHeader: 'Advertisement',
  adMediaExternalLink: 'Visit external link',
  adultContentAccept: 'View adult content',
  adultContentMessage1: 'The content you are trying to access may include sexually explicit material and is intended for an adult audience. By accessing this content, you confirm that you are of legal age to view sexually explicit material according to the applicable laws, and you agree not to share it with minors or individuals who are not authorized to access it.',
  adultContentMessage2: "By clicking 'View adult content', you agree to take full responsibility for your viewing. If you are not of legal age or prefer not to view this content, please click 'Cancel'.",
  bookFormLabelKeywords: 'Keywords (separated by a comma):',
  bookFormLabelMainCategory: 'Main category:',
  bookFormLabelNbPages: 'Number of pages:',
  bookFormLabelSortByNewest: 'Show newest first',
  bookFormLabelSubcategory: 'Subcategory:',
  bookFormLabelYears: 'Years of publication:',
  bookFormText1: 'Choose a category to receive random book suggestions:',
  bookFormText2: 'Then select a subcategory:',
  bookFormText3: 'You can also add optional filters.',
  bookHeaderAudience: 'Target audience',
  bookHeaderAuthorBio: 'About the author',
  bookHeaderCollection: 'Collection',
  bookHeaderDimensions: 'Dimensions',
  bookHeaderImages: 'Preview',
  bookHeaderLanguage: 'Language',
  bookHeaderNbPages: 'Print length',
  bookHeaderPublicationDate: 'Publication date',
  bookHeaderPublisher: 'Publisher',
  bookHeaderSellers: 'See on',
  bookHeaderSeries: 'Series',
  bookHeaderSocial: 'Follow the author!',
  bookReadAnExcerpt: 'Read an excerpt',
  bookTextCollection1: 'Book in the collection ',
  bookTextCollection2: ' by publisher ',
  bookTextSeries1: 'Book ',
  bookTextSeries2: 'in the series ',
  buttonAccept: 'Accept',
  buttonBack: 'Back',
  buttonCancel: 'Cancel',
  buttonClose: 'Close',
  buttonDelete: 'Delete',
  buttonDownload: 'Download',
  buttonSave: 'Save',
  buttonUpload: 'Upload',
  cookieConsentAccept: 'Accept all cookies',
  cookieConsentRefuse: 'Refuse everything',
  cookieConsentText: 'This site uses cookies to enhance your user experience and analyze traffic.',
  fileLikedBooks: 'my-liked-books',
  filterHeader: 'Search filters',
  footer1: 'Powered with love ❤️, coffee ☕, and by ',
  footer2: 'A Simple Tiger.',
  generalAttention: 'Warning',
  generalClickToDisplay: 'Click to display',
  generalLinkCopied: 'Link copied!',
  generalNoOptions: 'No options available',
  generalWattpadBook: 'Wattpad book',
  homeFooter1: 'Powered by ',
  homeFooter2: 'A Simple Tiger',
  homeSubheader11: 'Find your',
  homeSubheader12: 'literary match!',
  homeSubheader21: 'Knowledge',
  homeSubheader22: 'is power!',
  homeSubheader31: 'Who is your',
  homeSubheader32: 'literary crush?',
  homeSubheader41: 'Your passion, your book,',
  homeSubheader42: 'your universe!',
  homeSubheader51: 'Literary',
  homeSubheader52: 'treasure hunt!',
  homeSubheader61: 'Open a book,',
  homeSubheader62: 'awaken passions!',
  homeSubheader71: 'Find love',
  homeSubheader72: 'between the lines!',
  inputMax: 'max',
  inputMin: 'min',
  inputSelect: 'Select...',
  likesHeader: 'Liked books',
  likesInfo: 'The liked books will remain saved in the cache, even if you close the page or the app.',
  likesQuestionDeletion: 'Are you sure you want to remove this book?',
  noResultSearch: 'No results found.',
  noResultSliding: 'The shelf is empty.',
  noResultEnd: 'Please try a new search.',
  pageNotFound: "Oops! I can't find the page you're looking for.",
  pages: 'pages',
  pageCollectionHeader1: 'Collection',
  pageCollectionHeader2: 'by publisher ',
  pageSeriesHeader: 'Series',
  seeAll: 'See all',
  seeBrowse: 'Browse',
  seeLess: 'See less',
  seeMore: 'See more',
  tooltipBack: 'Back',
  tooltipDislike: 'Skip',
  tooltipHome: 'Home',
  tooltipLike: 'Like',
  tooltipSearch: 'Search',
  tooltipShare: 'Share',
  websiteName: 'Bookyverse',
  ratingAccordingTo: 'According to the users of',
  settingsAdultContent: 'View adult content',
  settingsCookieConsent: 'Accept all cookies',
  settingsHeader: 'Settings',
  settingsLanguage: 'Language',
  settingsTutorial: 'Review the tutorial',
  tutorialAccept: "OK, I'm ready!",
  tutorialDislikeButton: 'Skip to the next book.',
  tutorialFilterIcon: 'Access search filters or start a new search.',
  tutorialHeader: 'Tutorial',
  tutorialLikeButton: 'Like the book and add it to the liked books.',
  tutorialListOfLikesIcon: 'Access the liked books.',
  tutorialShareButton: 'Get the link to the book to share.',
  tutorialText: 'To understand this page, please read the content of the tooltips carefully.',
};
